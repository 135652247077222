import React from "react";
import { Typography } from "@mui/material";

function PreTitle({ title, color, align = "left" }) {
  return (
    <Typography
      fontWeight={700}
      color={color}
      textAlign={align}
      fontSize={{ xs: "16px", sm: "20px" }}
    >
      {title}
    </Typography>
  );
}

export default PreTitle;
