import React from "react";
import styled from "@emotion/styled";
import Tabs from "../Tabs";

function LandingsTabs({value, valueSet, tabs, tabColor, tabBgColor}) {
  const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
      gap: 24px;
      flex-wrap: wrap;
      justify-content: center;
  }
  .Mui-selected {
      color: white !important;
      background-color: ${tabColor} !important;
  }  
    `;

  return (
    <CustomTabs
      indicatorColor={tabColor}
      value={value}
      tabs={tabs}
      onChange={(_, newIndex) => valueSet(newIndex)}
      tabCustomStyle={{
        padding: "0 12px",
        fontWeight: 600,
        minHeight: "29px",
        borderRadius: "30px",
        color: tabColor,
        backgroundColor: tabBgColor,
      }}
    />
  );
}

export default LandingsTabs;
