import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import Slider from "react-slick";
import Container from "../Container";
import PreTitle from "./PreTitle";
import ImageComponent from "./ImageComponent";

const settings = {
  speed: 300,
  fade: true,
  dots: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  cssEase: "linear",
  slidesToScroll: 1,
  autoplaySpeed: 2000,
};

function DefaultSection({
  multimedia,
  pretitle,
  pretitleColor,
  pretitleAlign,
  title,
  titleVariant = "h2",
  description,
  buttons,
  buttonsJustify = multimedia ? { xs: "center", md: "flex-start" } : { xs: "center" },
  grid = {
    columnRight: {
      md: 6,
    },
    columnLeft: {
      md: 6,
    },
  },
  imgAlt = "Allfunds",
  isSlider = false,
  isVideo = false,
  direction = "row",
  containerPy = { xs: 8, md: 10, lg: 8 },
  ...params
}) {
  return (
    <Container component="section" py={containerPy} {...params}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        flexDirection={direction}
      >
        <Grid item xs={12} md={multimedia ? grid.columnLeft.md : 12}>
          <Stack spacing={2}>
            <Stack spacing={1} component="header">
              {pretitle && (
                <PreTitle
                  title={pretitle}
                  color={pretitleColor}
                  align={pretitleAlign}
                />
              )}
              {title && (
                <Typography variant={titleVariant}>
                  <Trans>{title}</Trans>
                </Typography>
              )}
            </Stack>
            {description}
            {buttons && (
              <Box display="flex" justifyContent={buttonsJustify}>
                {buttons}
              </Box>
            )}
          </Stack>
        </Grid>
        {multimedia && (
          <Grid
            item
            xs={12}
            alignItems="center"
            md={grid.columnRight.md}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            {isSlider && (
              <Box style={{ width: "100%" }}>
                <Slider {...settings}>
                  {multimedia.map((item, index) => (
                    <Box key={index}>
                      <img
                        src={item.image}
                        alt={`Carousel img ${index + 1} | Allfunds`}
                        style={{ objectFit: "contain" }}
                        width="100%"
                      />
                    </Box>
                  ))}
                </Slider>
              </Box>
            )}
            {!isSlider && (
              isVideo ? (
                <video muted autoPlay playsInline src={multimedia} width="100%" height="100%" />
              ) : (
                <ImageComponent src={multimedia} alt={imgAlt} />
              )
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default DefaultSection;
