import React from "react";
import { Tab, Tabs as MUITabs } from "@mui/material";
import { theme } from "../theme";

function Tabs({
  children, tabs, isDark = false, tabCustomStyle, indicatorColor, ...rest
}) {
  return (
    <MUITabs
      indicatorColor={indicatorColor || "secondary"}
      variant="scrollable"
      scrollButtons={false}
      {...rest}
    >
      {tabs?.map((value) => (
        <Tab
          style={
            tabCustomStyle || {
              color: isDark ? "white" : theme.palette.primary.main,
              fontWeight: 600,
              fontSize: "20px",
            }
          }
          label={value}
          value={value}
        />
      )) || children}
    </MUITabs>
  );
}
export default Tabs;
