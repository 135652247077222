import React from "react";
import { Box } from "@mui/material";

function ImageComponent({src, alt = "Allfunds", extraStyles}) {
  return (
    <Box component="figure" m={0} sx={extraStyles}>
      <img src={src} alt={alt} width="100%" height="100%" />
    </Box>
  );
}

export default ImageComponent;
